export default {
  formTitle: "Kreditwunsch als Selbständige/r?",
  formSubTitle: "Hier finden Sie Ihr passendes Angebot	",
  heading: "Schnelle Auszahlung",
  subHeading: "100% Kostenlos und Unverbindlich",
  notice: `Selbständige Personen haben oft besondere Probleme einen Kredit zu erhalten. Besonders hart trifft dies Existenzgründer da diese meist nicht über die Notwendige Kapitalbasis verfügen. Diese Probleme gehören mit unseren Leistungen der Vergangenheit an.

  Möchten Sie Ihren Wunschredit trotz Selbständigkeit schnell und unbürokratisch erhalten? Die Sofort24Kredit vermittelt zuverlässig an Ihre persönliche Situation angepasste Finanzierungen.
  
  Gute Beziehungen zu Banken im In- und Ausland ermöglichen es uns auch Selbstständigen zinsgünstige Kredite zu vermitteln und damit die üblichen Abweisungen und Enttäuschungen zu vermeiden.`,
  itemsTitle: "Ihre Vorteile in der Übersicht:",
  items: [
    "Angebotserstellung ist für Sie 100% kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
    "Die Antragsprüfung undEntscheidung erfolgt sofort online!",
    "Zeitraubende Bankbesuche entfallen",
    "Sofortige Antragsannahme ist garantiert!",
    "Schnell, diskret und einfach",
    "Betrag meistens innerhalb von 24 Stunden verfügbar.",
    "Machen Sie mit Ihrem Geld was Sie wollen! (Keine Zweckbindung der Kredite)",
    "Unser freundlicher Kundenservice berät Sie professionell bei allen Anliegen",
    "Laufzeiten flexibel wählbar",
    "Individuell nach Ihrem Wunsch: Zinssatz fest oder flexibel während der Laufzeit",
    "Ohne aufwändige Vertreterbesuche, alles wird auf dem Postweg erledigt"
  ],
  tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
